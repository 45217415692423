import { useRef, useEffect } from 'react';

import Bullet from './elements/Bullet';

import directTopUp from './assets/direct-top-up.png';
import appClips from './assets/app-clips.png';
import cclp from './assets/cclp.png';
import newsPlusPanera from './assets/news-plus-panera.png';
import idsInWallet from './assets/ids-in-wallet.png';
import moreSecure from './assets/more-secure.png';

export default function WorkApple () {
  const riverRef = useRef(null);

  const hoverSpeed = .01;
  const defaultSpeed = 0.05;
  let positionX = 0;
  let isRunning = true;

  const cloneRiver = () => {
    const list = riverRef.current.firstElementChild;
    const clone = list.cloneNode(true);
    clone.classList.add("clone");
    riverRef.current.appendChild(clone);
  }

  const startTranslate = (content, positionX) => {
    const listWidth = content.firstElementChild.scrollWidth;
    let timeElapsed = 0;
    let previousTimestamp;
    let hover = false;

    content.addEventListener('mouseenter', () => {
      hover = true;
    });
    content.addEventListener('mouseleave', () => {
      hover = false;
    });

    function loop(timestamp) {
      if (!previousTimestamp) {
        previousTimestamp = timestamp;
      }

      if (isRunning) {
        let speed;

        if (hover) {
          speed = hoverSpeed;
        } else {
          speed = defaultSpeed;
        }

        timeElapsed = (timestamp - previousTimestamp);
        positionX = positionX - (timeElapsed * speed);
        content.style.transform = `translateX(${positionX}px)`;

        if (positionX <= -Math.abs(listWidth)) {
          positionX = 0;
        }

        previousTimestamp = timestamp;
        window.requestAnimationFrame(loop);
      }
    }
    window.requestAnimationFrame(loop);
  }

  useEffect(() => {
    cloneRiver();
    startTranslate(riverRef.current, positionX);
  }, []);

  return (
    <>
      <div className="flex flex-col items-center pb-[96px]">
        <h2 className="text-4xl md:text-5xl mb-[36px]">Apple</h2>
        <div className="bg-[#550055]/[0.04] w-[80%] max-w-[640px] mb-[48px] px-[28px] py-[26px] border border-[#550055]/[0.04] rounded-md shadow-lg shadow-[#550055]/[0.05]">
          <ul className="text-lg md:text-xl px-[5%]">
            <li className="ml-[15px] mb-[6px]"><Bullet/> Built high traffic, pixel perfect web pages for the Services Marketing&nbsp;department</li>
            <li className="ml-[15px] mb-[6px]"><Bullet/> Drastically improved efficiency and scalability by creating dozens of reusable page templates&nbsp;&&nbsp;sections</li>
            <li className="ml-[15px]"><Bullet/> Led, taught, and trained a small group of junior&nbsp;developers</li>
          </ul>
        </div>
        <p className="text-lg md:w-[600px] lg:w-[700px] mb-[24px] px-[10%] md:px-0">This was a very fun role, where I got to build numerous pages seen by millions of people, and had the opportunity to learn and use some of the cool Javascript scroll animations that Apple is known for. From the beginning, I built everything with scalability and reusability in mind, and by the end of my tenure I’d amassed dozens of reusable templates and page components that now allow for non-developers to build and launch pages in less than an&nbsp;hour.</p>
        <div className="flex flex-nowrap items-end h-[288px] mt-[60px]" ref={riverRef}>
          <ul className="list-none inline-flex">
            <li className="w-[446px] h-[288px] mx-[20px] shrink-0 rounded-[12px]">
              <img className="rounded-[12px] shadow-lg" src={directTopUp} alt="Apple Direct Top Up" />
            </li>
            <li className="w-[446px] h-[288px] mx-[20px] shrink-0 rounded-[12px]">
              <img className="h-[100%] rounded-[12px] shadow-lg" src={moreSecure} alt="Apple more secure" />
            </li>
            <li className="w-[446px] h-[288px] mx-[20px] shrink-0 rounded-[12px]">
              <img className="rounded-[12px] shadow-lg" src={cclp} alt="Apple cross content" />
            </li>
            <li className="w-[446px] h-[288px] mx-[20px] shrink-0 rounded-[12px]">
              <img className="rounded-[12px] shadow-lg" src={appClips} alt="Apple App Clips" />
            </li>
            <li className="w-[446px] h-[288px] mx-[20px] shrink-0 rounded-[12px]">
              <img className="h-[100%] rounded-[12px] shadow-lg" src={idsInWallet} alt="Apple IDs in Wallet" />
            </li>
            <li className="w-[446px] h-[288px] mx-[20px] shrink-0 rounded-[12px]">
              <img className="rounded-[12px] shadow-lg" src={newsPlusPanera} alt="Apple News+ Panera" />
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};