import { motion } from 'framer-motion';

export default function Hero (props) {
  const buttonVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: {
        duration: 3,
        delay: 0.5
      }
    },
    hovered: {
      scale: 1.025,
      backgroundColor: '#fff',
      color: '#000',
      transition: {
        duration: 0.2
      }
    }
  };

  return (
    <>
      <div className="bg-[url('../public/hero.svg')] bg-[#330033] flex flex-col w-full h-screen box-border">
        <div className="w-full h-[100px]">
          <div className="flex items-center justify-end max-w-screen-xl h-[100%] mx-auto pt-[20px] pr-[40px]">
            <motion.button
              className="w-[200px] h-[48px] border border-white rounded-lg text-xl text-white"
              variants={buttonVariants}
              initial="hidden"
              animate="visible"
              whileHover="hovered"
              onClick={() => props.handleScroll()}
            >
              Contact
            </motion.button>
          </div>
        </div>
        <div className="flex items-center md:justify-center w-full max-w-screen-2xl h-full mx-auto px-[10%]">
          <motion.div
            className="w-100 md:w-[600px] h-[400px] md:text-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 5, type: 'spring', delay: 0.25 }}>
            <h1
              className="text-5xl sm:text-6xl md:text-7xl text-white mb-[24px]"
            >
              James Felz
            </h1>
            <p className="text-3xl sm:text-5xl md:text-6xl text-white">Transforming ideas into <br className="sm:hidden"/>stunning web&nbsp;realities</p>
          </motion.div>
        </div>
      </div>
    </>
  );
};