import React, { useState, forwardRef } from 'react';

import axios from 'axios';

export default forwardRef(function Contact (props, ref) {
  const [ formState, setFormState ] = useState('Untouched');
  const [ formData, setFormData ] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [ errors, setErrors ] = useState({
    name: '',
    email: '',
    message: ''
  });

  const validateName = (name) => {
    if (name.length < 3) {
      return 'Name must be at least 3 characters';
    } else if (name.length > 50) {
      return 'Name can be no longer than 50 characters';
    } else {
      return '';
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      return 'Invalid email address';
    }
    return '';
  };

  const validateMessage = (message) => {
    if (message.length < 6) {
      return 'Message must be at least 6 characters';
    } else if (message.length > 500) {
      return 'Message can be no longer than 500 characters';
    } else {
      return '';
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value});
    setErrors({ ...errors, [name]: '' });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const nameError = validateName(formData.name);
    const emailError = validateEmail(formData.email);
    const messageError = validateMessage(formData.message);

    if (nameError || emailError || messageError) {
      setErrors({
        name: nameError,
        email: emailError,
        message: messageError
      });
    } else {
      axios.post('/email', {
        name: formData.name,
        email: formData.email,
        message: formData.message
      }).then((res) => {
        console.log('success:', res);
        setFormState('Success');
      }).catch((e) => {
        console.log('error:', e);
        setFormState('Error');
      });
    }
  };

  const handleReset = (e) => {
    e.preventDefault();

    setFormData({name: '', email: '', message: ''});
    setErrors({name: '', email: '', message: ''});
    setFormState('Untouched');
  };

  function renderForm() {
    return (
      <>
        <h2 className="text-5xl md:text-6xl mb-[12px] text-white text-center">Get in touch</h2>
        <form className="flex flex-col w-full md:w-[600px] mx-auto" onSubmit={handleSubmit}>
          <div className="flex items-center justify-between">
            <label className="text-xl text-white py-[8px]" htmlFor="name">Your name:</label> {errors.name && <p className="mb-[-4px] text-sm md:text-base text-white">{errors.name}</p>}
          </div>
          <input
            className={`text-xl px-[8px] mb-[8px] py-[6px] rounded-md outline-[#880088] ${errors.name ? 'bg-error-red' : ''}`}
            name="name"
            type="text"
            value={formData.name}
            onChange={handleInputChange}
          />
          <div className="flex items-center justify-between">
            <label className="text-xl text-white py-[8px]" htmlFor="email">Your email:</label> {errors.email && <p className="mb-[-4px] text-sm md:text-base text-white">{errors.email}</p>}
          </div>
          <input
            className={`text-xl px-[8px] mb-[8px] py-[6px] rounded-md outline-[#880088] ${errors.email ? 'bg-error-red' : ''}`}
            name="email"
            type="email"
            value={formData.email}
            onChange={handleInputChange}
          />
          <div className="flex items-center justify-between">
            <label className="text-xl text-white py-[8px]" htmlFor="message">Message:</label> {errors.message && <p className="mb-[-4px] text-sm md:text-base text-white">{errors.message}</p>}
          </div>
          <textarea
            className={`text-xl px-[8px] py-[6px] rounded-md resize-none outline-[#880088] ${errors.message ? 'bg-error-red' : ''}`}
            name="message"
            rows="8"
            value={formData.message}
            onChange={handleInputChange}
          ></textarea>
          <input className="self-center bg-white px-[48px] py-[8px] mt-[40px] rounded-md text-xl cursor-pointer" type="submit" value="Submit" />
        </form>
      </>
    );
  };

  function renderFormSuccess() {
    return (
      <>
        <div className="flex flex-col w-full md:w-[600px] mx-auto">
          <h2 className="text-5xl md:text-6xl mb-[12px] text-white text-center">Email sent!</h2>
          <p className="text-lg text-white text-center">If you need to send another message, click below to reset the form.</p>
          <button className="self-center bg-white px-[48px] py-[8px] mt-[40px] rounded-md text-xl cursor-pointer" onClick={handleReset}>Reset</button>
        </div>
      </>
    );
  };

  function renderFormError() {
    return (
      <>
        <div className="flex flex-col w-full md:w-[600px] mx-auto">
          <h2 className="text-5xl md:text-6xl mb-[12px] text-white text-center">Something went wrong!</h2>
          <p className="text-lg text-white text-center">You can try sending another message by clicking the buttom below. Otherwise, you can also reach me at jamesfelz@gmail.com.</p>
          <button className="self-center bg-white px-[48px] py-[8px] mt-[40px] rounded-md text-xl cursor-pointer" onClick={handleReset}>Reset</button>
        </div>
      </>
    );
  };

  let formSection;

  switch(formState) {
    case 'Untouched':
      formSection = renderForm();
      break;
    case 'Success':
      formSection = renderFormSuccess();
      break;
    case 'Error':
      formSection = renderFormError();
      break;
    default:
      formSection = renderForm();
  }

  return (
    <>
      <div ref={ref} className="bg-[#330033] width-full h-[820px] py-[80px]">
        <div className="mx-w-screen-2xl mx-auto px-[10%]">
          {formSection}
        </div>
      </div>
    </>
  );
});